import { Modal, Space, Typography } from 'antd';

const legendItemsObj = [
    {
        colorName: "Purple",
        colorCode: "#C88CC8",
        description: "Unknown or less than 0MVA"
    },
    {
        colorName: "Red",
        colorCode: "#FF8C8C",
        description: "Less than 0.5MVA"
    },
    {
        colorName: "Amber",
        colorCode: "#FFDB8C",
        description: "0.5-2MVA"
    },
    {
        colorName: "Green",
        colorCode: "#8CFF8C",
        description: "More than 2MVA"
    }
];

const LegendModal = ({ open, setShowLegend }: { open: boolean, setShowLegend: any }) => {
    return (
        <Modal maskClosable={false} onCancel={() => setShowLegend(!open)} open={open} footer={null} title="Available capacity" className='legendModal' width={480}>
            <Typography.Text>The colours represent the capacity including the requested load</Typography.Text>
            <Space direction='vertical' style={{ marginTop: 20, gap: 14 }}>
                {legendItemsObj.map((item) => {
                    return (
                        <Space direction="horizontal" style={{ gap: 20 }}>
                            <div style={{ height: 35, width: 35, background: `${item.colorCode}`, marginRight: 2, borderRadius: 4 }}></div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Typography.Text style={{ fontWeight: 600 }}> {item.colorName}</Typography.Text>
                                <Typography.Text>{item.description}</Typography.Text>
                            </div>
                        </Space>)
                })
                }
            </Space>
        </Modal>
    );
};

export default LegendModal;